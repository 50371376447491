import React, { createContext } from 'react'
import ModalTerm from '../routes/Home/components/ModalTerm';
import ModalAuth from '../routes/Home/modalAuth';
import useHome from './useHome';

const AuthContext = createContext();
export default function AuthProvider({children}) {

    const {
        toggleModal,
        open,
        modal,
        setModalView,
        onLogin,
        loading,
        term,
        toggleModalTerm
    } = useHome();

    return(<AuthContext.Provider value={{
        toggleModal,
        open,
        modal,
        setModalView,
        onLogin,
        loading,
        term,
        toggleModalTerm
    }}>
        {children}
        <ModalAuth />
        <ModalTerm />
    </AuthContext.Provider>);
}

export {AuthContext};