import { Row } from "antd"
import ProductCard from "../../components/ProductCard";


const Products = ({data, title}) => {

    return (
      <div className="product_main">
        <h1>{title}</h1>
        <Row gutter={[10, 0]} style={{width: '100%'}}>
            {data.map((d) => <ProductCard key={d.id} data={d} />)}
        </Row>
      </div>
    );
}

export default Products;
