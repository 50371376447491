import { message } from "antd";
import { useState } from "react";
import { paisesList } from "../utils/paises";
import { states } from "../utils/states";
import { PlusOutlined } from "@ant-design/icons";
import { addData, opt2, opt3, UploadFile } from "../utils/api";
import moment from "moment";
import { getStorage, USER } from "../utils/storage";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";


const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });


export default function useCreateProduct() {
  const {t} = useTranslation();
    const [category, setCategory] = useState(0);
    const [citys, setCitys] = useState([]);
    const [user] = useState(getStorage(USER))
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    // imagen upload
    const [fileList, setFileList] = useState([])
    const [images, setImages] = useState([])
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');

    const onChangeCategory = (val) => {
        const index = paisesList.findIndex(item => item.name === val)
        setCitys(states.filter(item => item.id_country === paisesList[index].id))
        // setCategory(val)
    }

    const rulerOnlyLetter = [
        {required: true, message: t('input.required')},
        {
            // required: true,
            min: 2,
            message: t('input.minletter')
        },{
            pattern: /^[a-zA-ZÀ-ÖØ-öø-ÿ 0-9$#%¿?]+$/i,
            message: t('input.onlyletter')
        }
    ]

    // upload image
    const handleChange = ({ file, fileList: newFileList }) => {
        if(newFileList.length > 4) {
            return message.error(t('alerts.max4images'))
        }
        
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error(t('alerts.onlyjpgpng'));
            setFileList(fileList);
            setImages(images)
            return;
          }
          const isLt5M = file.size / 1024 / 1024 < 10;
        if (!isLt5M) {
            message.error(t('alerts.max10mb'));
            setFileList(fileList);
            setImages(images)
            return;
        }
        setImages([file].concat(images))
        setFileList(newFileList);
    }



    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      setPreviewImage(file.url || file.preview);
      setPreviewOpen(true);
      setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };



    const uploadButton = (
        <div>
          <PlusOutlined />
          <div
            style={{
              marginTop: 8,
            }}
          >
            {t('creat.upload')}
          </div>
        </div>
      );

    const onFinish = async (values) => {
        try {
            let results = [];
            setLoading(true);
            for await (let p of values.photo.fileList) {
                let fd = new FormData();
                fd.append('file', p.originFileObj);
                try {
                    const response = await UploadFile('/upload/product', fd, opt3());
                    results.push(response);
                } catch (error) {
                  console.log(error);
                    message.error(t('alerts.erroruploadimg'))
                    setLoading(false);
                    return;
                }
            }
            console.log(results);
            const response = await addData('products', 
            {
                ...values, 
                photo: results[0], 
                category: parseInt(values.category), 
                fecha: moment().format('DD MMM YYYY HH:mm:ss'), 
                id_user: user.id, 
                visible: true, 
                image: results[1], 
                image1: results[2],
                image2: results[3]
                // show_dire
            }, opt2())
            message.success(t('alerts.successprod'));
            history.push('/customer');
            setLoading(false);
        } catch (error) {
            console.log(error)
            setLoading(false);
            message.error(t('alerts.errorcreatepost'));
        }
    }

    return {
        onChangeCategory,
        category,
        citys,
        onFinish,
        rulerOnlyLetter,
        fileList,
        previewImage,
        previewOpen,
        previewTitle,
        handleCancel,
        handlePreview,
        handleChange,
        uploadButton,
        loading
    }
}