import React, { Component } from 'react';
import {  Menu, Row, Col } from 'antd';
import MenuP from '../../components/Menu';
import { Link, withRouter} from 'react-router-dom';

import Cookies from 'js-cookie';
import { Helmet } from 'react-helmet';
import { Translation } from 'react-i18next';

class Customer extends Component {
    constructor(props) {
        super(props);
        this.state = { 
          customers: [],
          chatlist: [],
          collapsed: false,
          search: ''
         }
    }

    async componentDidMount() {
      const token = Cookies.get('token');
      if(!token) {
        this.props.history.push('/login');
      }

    }

    onCollapse = collapsed => {
        console.log(collapsed);
        this.setState({ collapsed });
      };

      onSearch = (e) => {
        this.setState({search: e.target.value});
      }
 
     onKey = (e) => {
         if(e.key === "Enter") {
             e.preventDefault();
             e.stopPropagation();
             this.props.history.push('/catalogo/s/'+this.state.search);
         }
     }

      
    render() { 
        return ( 
          <Translation>
            {(t) => <div className="customer_main">
              <Helmet>
                <title>Upgrap | Usuario</title>
              </Helmet>
              <MenuP onKey={this.onKey} onSearch={this.onSearch} search={true} catalogo={false} />
              <Row className='row_customer'>
                <Col xs={24} sm={24} md={4} lg={6}>
                <Menu
        onClick={this.handleClick}
        style={{ width: '100%' }}
        defaultSelectedKeys={[window.location.href]}
        mode="inline"
      >
        <Menu.Item key="/customer">
          <Link to="/customer">{t('menu.publish')}</Link>
        </Menu.Item>
        <Menu.Item key="/personal">
          <Link to="/personal">{t('menu.edit')}</Link>
        </Menu.Item>
        <Menu.Item key="/chat">
          <Link to="/chat">{t('menu.chats')}</Link>
        </Menu.Item>
      </Menu>
                </Col>
                <Col xs={24} sm={24} md={20} lg={18}>
                  {this.props.view}
                </Col>
              </Row>
            </div>}</Translation>
         );
    }
}
 
export default withRouter(Customer);