import { Button, Card, Col, Row } from "antd";
import { Link } from "react-router-dom";
import { BASE_IMG } from "../../utils/api";
import i18next from 'i18next';
const Categories = ({data, title, textBtn}) => {
  // const currentLanguageCode = Cookies.get('i18next') || 'en';

    return (
      <div className="categorias_main">
        <Link to="/catalogo"><h1>{title}</h1></Link>
        <Row>
          {data.map((d) => (
            <Col key={d.id} xs={24} sm={24} md={12} lg={6}>
              <Link to={`/catalogo/p/1/${d.id}`}>
                <Card>
                    <img src={`${BASE_IMG}public/categories/${d.image}`} alt={d.name} />
                     <h5>{i18next.language === 'en' ? d.namei : d.name}</h5>
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
        <div style={{width: '100%', textAlign: 'right', marginTop: 20}}>
          <Button type="primary"><Link to="/catalogo">{textBtn}</Link></Button>
        </div>
      </div>
    );
}

export default Categories;