import { Form, Input, Modal } from 'antd';
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next';
import { ProfileContext } from '../EditData';

export default function ModalChangePass() {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const {
        modalCp,
        toggleModalChangepass,
        onChangePass,
        loading
    } = useContext(ProfileContext)

    const onSendData = async () => {
        const values = await form.validateFields();
        if(await onChangePass(values)) {
            toggleModalChangepass();
            form.resetFields();
        }
    }

    return(
        <Modal 
        confirmLoading={loading}
        onOk={onSendData}
        cancelText={t('auth.recooverpass.cancel')}
        okText={t('edit.save')}
        onCancel={() => {
            toggleModalChangepass();
            form.resetFields();
        }} 
        open={modalCp} title={t('edit.change')}>
            <Form form={form} layout='vertical'>
                <Form.Item name="password" label={t('edit.currentpass')} rules={[{
                        required: true,
                        message: t('input.required')
                    },
                    {
                        min: 2,
                        message: t('input.minpass')
                    },
                    {
                        max: 16,
                        message: t('input.maxpass')
                    },]}>
                    <Input.Password placeholder='**********' />
                </Form.Item>
                <Form.Item name="newPass" label={t('edit.newpass')} rules={[{
                        required: true,
                        message: t('input.required')
                    },
                    {
                        min: 8,
                        message: t('input.minpass')
                    },
                    {
                        max: 16,
                        message: t('input.maxpass')
                    },
                    {
                        pattern: /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,16}$/i,
                        message: t('input.pass')
                    }]}>
                    <Input.Password placeholder='**********' />
                </Form.Item>
            </Form>
        </Modal>
    );
}