import { Col, message, Row } from 'antd';
import React, { createContext, useEffect } from 'react'
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Footer from '../../components/Footer';
import Menu from '../../components/Menu';
import useHome from '../../hooks/useHome';
import { ImgHome } from '../../images';
import Categories from './categorias';
import Products from './Products';
import StepsPublish from './Steps';
import What from './What';
import { useTranslation } from "react-i18next";

let initial = false;
// const HomeContext = createContext()
export default function Home() {
    const {t} = useTranslation();
    const {categories, 
        products, 
        onKey, 
        onSearch, 
        getCategories,
        getProducts,
    } = useHome();

    useEffect(() => {
        if (!initial) {
            getCategories()
                getProducts()
            initial = true
        }
        return () => {
            initial = false;
        }
    }, [])

    return(<div className="home_main">
                <Helmet>
                    <title>Upgrap | Inicio</title>
                </Helmet>
                <Menu onKey={onKey} onSearch={onSearch} search={true} catalogo={false} />
                <div className="portada">
                    <Row style={{width: '100%'}} align="middle" justify="space-between">
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <h2>{t('home.portada.h2')}</h2>
                            <h1>{t('home.portada.h1.1')}<span> {t('home.portada.h1.2')}</span></h1>
                            <h4>{t('home.portada.h4')}</h4>
                            <Link to="/register">{t('home.portada.a')}</Link>
                        </Col>
                        <Col style={{textAlign: 'center'}} xs={24} sm={24} md={12} lg={12}>
                            <ImgHome width="80%" />
                        </Col>
                    </Row>
                </div>
                <div style={{padding: '5%'}}>
                <StepsPublish />
                <What />
                <Categories title={t('home.categories')} textBtn={t('home.btn_more')} data={categories} />
                {products.length > 0 && <Products title={t('home.last_products')} data={products} />}
                </div>
                <Footer />
                
            </div>);
}

// export {HomeContext}