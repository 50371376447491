import './App.less';
import { HashRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import Home from './routes/Home';
import 'pace-js';
import 'pace-js/themes/green/pace-theme-minimal.css';
import SignIn from './containers/SignIn';
import SignUp from './containers/SignUp';
import Customer from './routes/Customer';
import Catalogo from './routes/catalogo';
import Product from './routes/Product';
import Publish from './routes/Customer/publish';
import EditData from './routes/Customer/EditData';
import CreateProduct from './routes/Customer/CreateProduct';
import Chat from './routes/Customer/Chat';
import LostPassword from './containers/LostPassword';
import EditProduct from './routes/Customer/EditProduct';
import VerifyCode from './containers/VerifyCode';
import AuthProvider from './hooks/useAuthContext';
import TermsAndCond from './routes/term';
// import TimeAgo from 'javascript-time-ago'
// import es from 'javascript-time-ago/locale/es'
// TimeAgo.addDefaultLocale(es)


function App() {
  return (
    
    <Router>
      <Switch>
        <Route exact path="/home" component={() => <AuthProvider><Home /></AuthProvider>} />
        <Route exact path="/login" component={() => <SignIn />} />
        <Route exact path="/lostpassword" component={() => <AuthProvider><LostPassword /></AuthProvider>} />
        <Route exact path="/register" component={() => <SignUp />} />
        <Route exact path="/customer" component={() => <Customer view={<Publish />} />} />
        <Route exact path="/personal" component={() => <Customer view={<EditData />} />} />
        <Route exact path="/create" component={() => <Customer view={<CreateProduct />} />} />
        <Route exact path="/edit/:id" component={() => <Customer view={<EditProduct />} />} />
        {/* <Route  path="/chat" component={() => <Chat />} /> */}
        <Route path="/chat/:id?" component={() => <Customer view={<Chat />} />} />
        {/* <Route exact path="/catalogo" component={() => <Catalogo />} /> */}
        {/* <Route exact path="/catalogo/:page" component={() => <Catalogo />} /> */}
        <Route path="/catalogo/p/:page?/:categorie?" component={() => <AuthProvider><Catalogo /></AuthProvider>} />
        <Route exact path="/catalogo/s/:search/:page" component={() => <AuthProvider><Catalogo /></AuthProvider>} />
        <Route exact path="/product/:id" component={() => <AuthProvider><Product /></AuthProvider>} />
        <Route exact path="/verifycode/:code" component={() => <AuthProvider><VerifyCode /></AuthProvider>} />
        <Route exact path="/terms" component={() => <TermsAndCond />} />
        <Redirect to="/home" />
      </Switch>
    </Router>
    
  );
}

export default App;
