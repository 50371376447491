import { Link } from 'react-router-dom';
import footer from '../images/footer.svg';
import logo from '../images/logob.svg';
import tiktok from '../images/tiktok.svg';
import fb from '../images/fb.svg';
import instagram from '../images/instagram.svg';
import twitter from '../images/twitter.svg';
import { Grid, Select } from 'antd'
import i18next from 'i18next';
import { useTranslation } from "react-i18next";
const {Option}=Select;

const {useBreakpoint}= Grid;

const languages = [
    {code: 'en', name: 'English'},
    {code: 'es', name: 'Español'}
]
console.log(i18next.language)
const Footer = () => {
    const screens = useBreakpoint();
    const {t} = useTranslation();
    return(
        <footer style={{background: `url(${footer})`}} className="footer_">
            {screens.xs === true || (screens.sm === true && screens.md === false) ?
            <img src={logo} width="45%" /> : <img src={logo} width="15%" />}
            <div style={{marginTop: 20}}>
                <a className="a_footer" target="blank" href="https://m.facebook.com/upgrap/">
                    <img src={fb} />
                </a>
                <a className="a_footer" target="blank" href="https://www.instagram.com/upgrap/?igshid=qhltjrcnc4zy">
                    <img src={instagram} />
                </a>
                <a className="a_footer" target="blank" href="https://twitter.com/upgrap_">
                    <img src={twitter} />
                </a>
                <a className="a_footer" target="blank" href="https://vm.tiktok.com/ZMJ7T7Xbm/">
                    <img src={tiktok} />
                </a>
            </div>
            <p style={{color: "#fff", marginTop: 5, marginBottom: 0, paddingBottom: 0}}><strong>{t('footer.contanc')}:</strong> <a  style={{color: "#fff"}} href="mailto:support@upgrap.com">support@upgrap.com</a></p>
            <div>
                <Link to="/catalogo">{t('footer.menu.prod')}</Link> <span> | </span>
                <Link to="/login">{t('footer.menu.login')}</Link> <span> | </span>
                <Link to="/register">{t('home.portada.a')}</Link> <span> | </span>
                <Link to="/terms">{t('auth.create.term')}</Link>
                <Select value={i18next.language} onChange={(value) => {
                    window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
                    i18next.changeLanguage(value)
                }} style={{ width: 120 }} bordered={false}>
                    {languages.map(({code, name}) => 
                    <Option key={code} value={code}>{name}</Option>)}
                </Select>
            </div>
            <p>{t('footer.copy')}</p>
        </footer>
    )
}

export default Footer;