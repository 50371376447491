import { Divider, Typography } from 'antd';
import i18next from 'i18next';
import React from 'react'
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Footer from '../../components/Footer';
import Menu from '../../components/Menu';

const {Title} = Typography;

export default function TermsAndCond() {
    const {t} = useTranslation();

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, [])

    let renderTerm = {
        es: <TermEs />,
        en: <TermEn /> 
    }


    return(<div>
        <Menu catalogo={false} />
        <div className='terms_'>
            <Title level={3} className="terms_title">{t('auth.create.term')}</Title>
            <Divider />
        {renderTerm[i18next.language]}
        </div>
        <Footer />
    </div>);
}

function TermEs() {
    return (<p>
        <Title level={5}>01 - Capacidad</Title> <br />
        Los Servicios sólo están disponibles para personas que tengan capacidad
        legal para contratar (en adelante, el “Usuario”, o en plural, los
        “Usuarios”). No podrán utilizar los Servicios las personas que no tengan
        esa capacidad, los menores de edad o Usuarios de Upgrap que hayan sido
        suspendidos temporalmente o inhabilitados definitivamente. Para
        registrar un Usuario como Empresa, se deberá contar con la capacidad
        suficiente como para contratar a nombre y representación de tal entidad
        como así también de obligar a la misma en los términos de este Acuerdo.{" "}
        <br />
        <br />
        <Title level={5}>02 - Registración</Title>
        <br />
        Es obligatorio completar el formulario de registración en todos sus
        campos con datos válidos para poder utilizar los servicios que brinda
        Upgrap. El Usuario deberá completar el mencionado formulario de
        registración con la información personal que le sea solicitada de manera
        exacta, precisa y verdadera (en adelante, los "Datos Personales") y
        asume el compromiso de actualizar los Datos Personales conforme resulte
        necesario. El Usuario presta expresa conformidad con que Upgrap utilice
        diversos medios para identificar sus datos personales, asumiendo el
        Usuario la obligación de revisarlos y mantenerlos actualizados. Upgrap
        NO se responsabiliza por la certeza de los Datos Personales de los
        Usuarios. Los Usuarios garantizan y responden, en cualquier caso, de la
        veracidad, exactitud, vigencia y autenticidad de sus Datos Personales. A
        su exclusiva discreción, Upgrap podrá requerir una registración
        adicional a los Usuarios que operen como concesionarias o inmobiliarias,
        como requisito para que dichos Usuarios accedan a paquetes especiales de
        publicaciones. En estos casos, una vez efectuada la registración
        adicional, las ofertas de venta de automóviles o inmuebles que realicen
        las concesionarias o inmobiliarias, respectivamente, sólo se publicarán
        en Upgrap a través de alguno de dichos paquetes o bajo las modalidades
        que Upgrap habilite para este tipo de Usuarios. Upgrap se reserva el
        derecho de solicitar algún comprobante y/o dato adicional a efectos de
        corroborar los Datos Personales, así como de suspender temporal o
        definitivamente a aquellos Usuarios cuyos datos no hayan podido ser
        confirmados. En estos casos de inhabilitación, se dará de baja todos los
        artículos publicados, así como las ofertas realizadas, sin que ello
        genere algún derecho a resarcimiento. <br />
        <br />
        Upgrap se reserva el derecho de solicitar una indemnización o daño
        ulterior ocasionado por el usuario. <br />
        <br />
        El Usuario accederá a su cuenta personal (en adelante, la "Cuenta")
        mediante el ingreso de su Apodo y clave de seguridad personal elegida
        (en adelante, la "Clave de Seguridad"). El Usuario se obliga a mantener
        la confidencialidad de su Clave de Seguridad. La Cuenta es personal,
        única e intransferible, y está prohibido que un mismo Usuario registre o
        posea más de una Cuenta. En caso que Upgrap detecte distintas Cuentas
        que contengan datos coincidentes o relacionados, podrá cancelar,
        suspender o inhabilitarlas. El Usuario será responsable por todas las
        operaciones efectuadas en su Cuenta, pues el acceso a la misma está
        restringido al ingreso y uso de su Clave de Seguridad, de conocimiento
        exclusivo del Usuario. El Usuario se compromete a notificar a Upgrap en
        forma inmediata y por medio idóneo y fehaciente, cualquier uso no
        autorizado de su Cuenta, así como el ingreso por terceros no autorizados
        a la misma. Se aclara que está prohibida la venta, cesión o
        transferencia de la Cuenta (incluyendo la reputación y calificaciones)
        bajo ningún título, salvo expresa autorización de Upgrap. Upgrap se
        reserva el derecho de rechazar cualquier solicitud de registración o de
        cancelar una registración previamente aceptada, sin que esté obligado a
        comunicar o exponer las razones de su decisión y sin que ello genere
        algún derecho a indemnización o resarcimiento.
        <br />
        <br />
        Está terminantemente prohibido publicar fotos con contenido sexual,
        drogas, violencia, entre otros contenidos ilícitos por ley vigente en el
        país que corresponde.
        <br />
        <br />
        Upgrap solamente se encarga de compartir información publicada por cada
        uno de sus usuarios. No se responsabiliza directa o indirectamente por
        los intercambios y/o transacciones realizados dentro de la plataforma.
        <br />
        <br />
        Upgrap no se responsabiliza por robos/secuestros y todo suceso que pueda
        ocurrir de ntro y fuera de la plataforma. El usuario es 100 %
        responsable de sus publicaciones hechas en la plataforma así como
        también de cualquier coordinación o reunión física o virtual entre
        usuarios de Upgrap.
        <br />
        <br />
      </p>)
}

function TermEn() {
    return (      <p>
        <Title level={5}>01 - capabilities</Title>  <br />
        The Services are only available to persons who have the
         legal to contract (hereinafter, the "User", or in the plural, the
         “Users”). The Services may not be used by persons who do not have
         that capacity, minors or Upgrap Users who have been
         temporarily suspended or permanently disabled. For
         register a User as a Company, you must have the capacity
         sufficient to contract in the name and on behalf of such entity
         as well as to bind it in the terms of this Agreement.{" "}
        <br />
        <br />
        <Title level={5}>02 - Registration</Title>
        <br />
        It is mandatory to complete the registration form in all your
        fields with valid data to be able to use the services it provides
        Upgrap. The User must complete the aforementioned form
        registration with the personal information that is requested in a manner
        exact, precise and true (hereinafter, the "Personal Data") and
        assumes the commitment to update the Personal Data as it is
        necessary. The User expressly agrees that Upgrap uses
        various means to identify your personal data, assuming the
        User the obligation to review them and keep them updated. upgrap
        It is NOT responsible for the accuracy of the Personal Data of the
        Users. The Users guarantee and respond, in any case, of the
        veracity, accuracy, validity and authenticity of your Personal Data. A
        In its sole discretion, Upgrap may require registration
        additional to Users who operate as concessionaires or real estate,
        as a requirement for said Users to access special packages of
        publications. In these cases, once the registration
        additionally, the offers of sale of automobiles or real estate that they carry out
        concessionaires or real estate, respectively, will only be published
        in Upgrap through any of these packages or under the modalities
        that Upgrap enables for this type of Users. Upgrap reserves the
        right to request any proof and/or additional data for purposes of
        corroborate the Personal Data, as well as temporarily suspend or
        definitively to those Users whose data could not be
        confirmed. In these cases of disqualification, all the
        articles published, as well as the offers made, without this
        generate any right to compensation. <br />
        <br />
        Upgrap reserves the right to request compensation or damage
         further caused by the user. <br />
        <br />
        The User will access his personal account (hereinafter, the "Account")
        by entering your Nickname and chosen personal security key
        (hereinafter, the "Security Key"). The User undertakes to maintain
        the confidentiality of your Security Key. The Account is personal,
        unique and non-transferable, and it is forbidden for the same User to register or
        have more than one Account. In case Upgrap detects different Accounts
        that contain matching or related data, you can cancel,
        suspend or disable them. The User will be responsible for all
        operations carried out on your Account, since access to it is
        restricted to the entry and use of your Security Key, of knowledge
        exclusive to the User. The User agrees to notify Upgrap in
        immediately and by appropriate and reliable means, any use not
        authorized of your Account, as well as the entry by unauthorized third parties
        to the same. It is clarified that the sale, assignment or
        Account transfer (including reputation and qualifications)
        under any title, unless expressly authorized by Upgrap. Upgrap se
        reserves the right to reject any application for registration or
        cancel a previously accepted registration, without being obliged to
        communicate or explain the reasons for their decision and without this generating
        any right to compensation or compensation.
        <br />
        <br />
        It is strictly forbidden to post photos with sexual content,
         drugs, violence, among other illegal content by law in force in the
         corresponding country.
        <br />
        <br />
        Upgrap is only responsible for sharing information published by each
         one of its users. It is not directly or indirectly responsible for
         the exchanges and/or transactions carried out within the platform.
        <br />
        <br />
        Upgrap is not responsible for theft / kidnapping and any event that may
         occur on and off the platform. User is 100%
         responsible for their publications made on the platform as well as
         also of any coordination or physical or virtual meeting between
         Upgrap users.
        <br />
        <br />
      </p>)
}