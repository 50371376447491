import React from 'react'
import { Form, Input, Button, Row, Col, message } from 'antd';
import bglogin from '../images/login.svg';
import Menu from '../components/Menu';
import { Helmet } from 'react-helmet';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { addData, getData, opt1 } from '../utils/api';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import { useState } from 'react';

export default function VerifyCode() {
    const [form] = Form.useForm();
    const params = useParams();
    const history = useHistory();
    const password = Form.useWatch('password', form);
    const {t} = useTranslation();

    const [loading, setLoading] = useState(false);
    const [expire, setExpire] = useState(false);
    const [email, setMail] = useState(false);
    const [token, setToken] = useState(false);

    useEffect(() => {
        fetchInit()
    },[])

    const fetchInit = async () => {
        const {code} = params;
        if(!code) {
            history.push('/')
        } else {
            await getData('verifycode/'+code, opt1).then(res => {
                const fechaExp = moment(res.info.expire).add(24, 'hour').format('DD MMM YYY HH:mm:ss')
                if( moment().format('DD MMM YYY HH:mm:ss') > fechaExp) {
                    message.info(t('lostpass.errcode'))
                    setExpire(true);
                    history.push('/')
                } else {
                    setMail(res.info.email)
                    setToken(res.info.code)
                //   this.setState({email: res.info.email, token: res.info.code})
                //     console.log('ok')
                }
            }).catch(err => {
                console.log(err)
              message.error(t('lostpass.errcode'));
            })
        }
    } 

    const onFinish = async (values) => {
        
        try {
            setLoading(true);
            const response = await addData('changepass', {
                email: email.trim(), 
                password: values.password.trim(), 
                token: token.trim()
            }, opt1);
            if(response.code !== 'success') {
                throw response;
            }
            message.success(t('alerts.updatedsuccess'));
            setLoading(false);
            history.push('/');
        } catch (error) {
            setLoading(false);
            message.error(t('lostpass.errcode'));
        }
      };

    return(<div className="login_main" style={{background: `url(${bglogin})`}}>
    <Helmet>
      <title>Upgrap | {t('lostpass.title')}</title>
    </Helmet>
      <Menu catalogo={false} search={false} />
      <div style={{width: '100%', marginTop: '5%'}}>
      <h1>{t('lostpass.title')}</h1>
      <p>{t('lostpass.text')}</p>
      <Row style={{width: '100%'}} justify="center">
          <Col xs={24} sm={24} md={12} lg={8}>
          <Form
          requiredMark={false}
          layout='vertical'
          form={form}
    name="basic"
    onFinish={onFinish}
  >
    <Form.Item
      name="password"
      label={t('edit.newpass')}
      rules={[{
        required: true,
        message: t('input.required')
    },
    {
        min: 8,
        message: t('input.minpass')
    },
    {
        max: 16,
        message: t('input.maxpass')
    },
    {
        pattern: /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,16}$/i,
        message: t('input.pass')
    }]}
    >
      <Input.Password placeholder={t('edit.newpass')} />
    </Form.Item>
    
    <Form.Item
      name="repassword"
      rules={[{
        required: true,
        message: t('input.required')
    },
    {
        min: 8,
        message: t('input.minpass')
    },
    {
        max: 16,
        message: t('input.maxpass')
    },
    {
        validator: (_, value) => password !== value ? Promise.reject(new Error(t('input.diffpass'))) : Promise.resolve(),
        message: t('input.diffpass')
    },
    {
        pattern: /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,16}$/i,
        message: t('input.pass')
    }]}
    label={t('auth.create.repass')}
    >
      <Input.Password  placeholder={t('auth.create.repass')} />
    </Form.Item>

    <Form.Item style={{marginTop: 20}}>
      <Button type="primary" loading={loading} htmlType="submit">
        {t('edit.save')}
      </Button>
    </Form.Item>
  </Form>
          </Col>
      </Row>
      </div>
  </div>);
}