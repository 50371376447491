import { message } from "antd";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { addData, deleteData, getDataById, opt2 } from "../utils/api";
import { getStorage, setStorage, USER } from "../utils/storage";
import useCronometer from "./useCount";

const timer = 30;

export default function usePublish() {
    const {time, Reset, Start} = useCronometer(timer);
    const {t} = useTranslation();
    const history = useHistory();
    const [products, setProducts] = useState([]);
    const [user, setUser] = useState(getStorage(USER));
    const [open, setOpen] = useState(false);
    const [code, setCode] = useState('');

    const getMyProducts = async () => {
        try {
            const response = await getDataById('products/customer/', user.id, opt2());
            setProducts(response);
        } catch (error) {
            message.error(t('alerts.errmyprod'));
        }
    }

    const openModal = () => {
        setOpen(true);
        if(time <= 0 || time === timer ) {
            
            reSendOTP()
        }
    };

    const createPublish = async () => {
        if(!user.phone || !user.prefix) {
            message.info(t('alerts.notphone'));
        } else if(!user.validate_phone) {
            try {
                openModal();
            } catch (error) {
                message.error(t('alerts.errsms'))
            }
        } else {
            history.push('/create');
        }
    }

    const reSendOTP = async () => {
        const data = {phone: user.prefix+user.phone, id_user: user.id, create_at: moment().format('DD MMM YYYY HH:mm:ss'), expire_at: moment().add(24, 'hour').format('DD MMM YYYY HH:mm:ss')}
        try {
            Reset();
            Start();
            await addData('send', data, opt2())
            message.success(t('alerts.sendsms'));
        } catch (error) {
            message.error(t('alerts.errsms'))
        }
    }

    const sendCode = async () => {
        try {
            const response = await addData('validate', {code, id_user: user.id}, opt2());
            if(response) {
                const r = {...user, validate_phone: true};
                setStorage(USER, {...r});
                setUser({...r})
                message.success(t('alerts.validatesms'))
                history.push('/create')
            } else {
                message.error(t('alerts.errvalidatesms'))
            }
        } catch (error) {
            message.error(t('alerts.err400'))
        }
    
    }

    const handleMenuClick = async (id) => {
        await deleteData('products/', id, opt2()).then(() => {
            message.success('Producto eliminado con exito');
            // console.log(res, key)
            setProducts(products.filter(p => p.id !== id));
        }).catch(err => message.error(t('alerts.errdelete')))
    }

    const closeModal = () => setOpen(false);

    const onChangeCode = (evt) => setCode(evt.target.value.replace(/\D|\-/,''))

    return {
        getMyProducts,
        products,
        time,
        open,
        closeModal,
        createPublish,
        handleMenuClick,
        user,
        code,
        reSendOTP,
        onChangeCode,
        sendCode
    }
    
}