import { useState } from "react";
import { useHistory } from "react-router-dom";
import { getData } from "../utils/api";
import { getStorage, USER } from "../utils/storage";

export default function useProducts() {
    const history = useHistory();
    const [products, setProducts] = useState({rows: [], limit: 10, page: 1, total_rows: 0})
    const [search, setSearch] = useState("")
    const [category, setCategory] = useState(0);
    const [country, setCountry] = useState(() => {
        const user = getStorage(USER);
        return !user ? 'Perú' : user.pais;
    })

    const getProducts = async (category = 0, page = 1, country) => 
    getData(`products?category=${category}&country=${country}&page=${page}`)
    .then(response => setProducts(response))
    .catch((err) => Promise.reject(err))

    const initProducts = (params) => {
        const page = params.page ? parseInt(params.page) : 1
        const cat = params.categorie ? parseInt(params.categorie) : 0;
        setCategory(cat);
        getProducts(cat, page, country)
    }

    const getProductsSearch = async (page = 1, search) => 
    getData(`products?q=${search}&page=${page}`)
    .then(response => setProducts(response))
    .catch((err) => Promise.reject(err))

    const resetDom = () => {
        window.scrollTo({top: 0, left: 0, behavior: 'auto'});
        setSearch("")
    }
    //* search
    const onSearch = (value) => {
        setSearch(value.target.value)
    }

    const onKey = (e) => {
        if(e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();
            
            if (search === null || search !== '' || search !== ' ') {
                history.push(`/catalogo/s/${search}/1`)
                getData(`products?page=1&q=${search}`)
                .then(response => setProducts(response))
                .catch((err) => Promise.reject(err))
            }
        }
    }

    const onchangeCountry = async (value) => {
        resetDom()
        
        setCountry(value);
        getProducts(0, 1, value)
    }

    const onChangeCategory =  async (value) => {
        resetDom()
        setSearch('')
        setCategory(value);
        getProducts(value, 1, country)
    }

    const onChangePage = async (value) => {
        resetDom()
        if(search !== '' && search !== ' ') {
            getProductsSearch(value, search)
            return;
        }
        getProducts(category, value, country)
    }

    return {
        products,
        search,
        onKey,
        country,
        initProducts,
        onchangeCountry,
        onChangeCategory,
        category,
        onChangePage,
        onSearch,
        getProductsSearch
    }
}