import { useTranslation } from "react-i18next";
import { Button, Input, Space, Grid, Menu as MenuA, Badge, Typography } from "antd"
import { Link, withRouter } from "react-router-dom"
import {FbIcon, InstagramIcon, Logo, TiktoIcon, TwitterIcon} from '../images'
import Cookies from 'js-cookie'
import {useContext, useEffect, useState} from "react"
import { MenuOutlined, WechatOutlined, HomeOutlined, LogoutOutlined, UserOutlined, BookOutlined } from '@ant-design/icons';
import notification from '../sounds/notification.ogg';
import { AuthContext } from "../hooks/useAuthContext"
// import News from "./AlertasWeb"
// import tiktok from '../images/tiktok.svg';
// import fb from '../images/fb2.svg';
// import instagram from '../images/instagram.svg';
// import twitter from '../images/twitter.svg';


const {useBreakpoint} = Grid;
const { SubMenu } = MenuA;
const {Text} = Typography;

const Menu = (props) => {
  const home = useContext(AuthContext)
    const screens = useBreakpoint();
    const {t} = useTranslation();
    const [user, setUser] = useState();
    const [dot, setDot] = useState(false);
    const [menuMovil, setMenuMovil] = useState(false);
    const [chatList, setChatList] = useState([]);

    useEffect(() => {
        const token = Cookies.get('token');
        const user = JSON.parse(window.localStorage.getItem('user'));
        setUser(token);
    }, [])

    function playNotification() {
        const aud = document.getElementById('audioId');
        aud.play();
    }

    const logout = async () => {
            Cookies.remove('token');
            localStorage.removeItem('user')
            props.history.push('/');
    }
    const shoMenu = async () => {
        setMenuMovil(!menuMovil);
    }
    const toChat = () => {
      props.history.push('/chat');
    }

    const hanlekey = (e) => {
        console.log(e)
        if(e.key === 'logout') {
            logout();
        } else {
            props.history.push(e.key);
        }
    }

    return (
      <>
      {/* <News /> */}
      <div className="menu_prima">
        <audio id="audioId" src={notification} autoPlay={false} loop={false} />
        <div className="c1">
          <Link to="/">
            <img src={Logo} />
          </Link>
          {screens.lg === true &&                     <div  className="rds">
              <a target="blank" href="https://m.facebook.com/upgrap/" className="iconC">
                <FbIcon className="iconR" />
              </a>
              <a target="blank" href="https://www.instagram.com/upgrap/?igshid=qhltjrcnc4zy" className="iconC">
                <InstagramIcon className="iconR" />
              </a>
              <a target="blank" href="https://twitter.com/upgrap_" className="iconC">
                <TwitterIcon className="iconR" />
              </a>
              <a target="blank" href="https://vm.tiktok.com/ZMJ7T7Xbm/" className="iconC">
                <TiktoIcon className="iconR" />
              </a>
            </div>}
        </div>

        {screens.lg === true && <div style={{display: 'flex', alignItems: 'center'}}>
            <Link to="/catalogo/p">{t('footer.menu.prod')}</Link>
            {props.search !== false && (
          <Input type="search" allowClear onKeyDown={props.onKey} onChange={props.onSearch} className="search" placeholder={t('menu.search')} />
        )}
        {props.catalogo !== false && (
          <Input
          allowClear
            onKeyDown={props.onKey}
            value={props.valueSearch}
            onChange={props.onChange}
            className="search"
            placeholder={t('menu.search')}
          />
        )}

            </div>}
            { screens.lg === false && props.search !== false && (
          <Input style={{marginRight: 10}} allowClear type="search" onKeyDown={props.onKey} onChange={props.onSearch} className="search" placeholder={t('menu.search')} />
        )}
        { screens.lg === false && props.catalogo !== false && (
          <Input
          allowClear
          type="search"
          onKeyDown={props.onKey}
          style={{marginRight: 10}}
            value={props.valueSearch}
            onChange={props.onChange}
            className="search"
            placeholder={t('menu.search')}
          />
        )}
        {screens.xs === true || screens.md === false || screens.lg === false ? (
          <div>
            <Button
              onClick={shoMenu}
              type="primary"
              icon={<MenuOutlined />}
            ></Button>
            <div>
              {!user ? (
                <MenuA
                onClick={hanlekey}
                  className={menuMovil === true ? "showMenu" : "menuMovil"}
                  style={{ width: 256 }}
                  defaultSelectedKeys={[window.location.href]}
                  mode="inline"
                >
                  <img src={Logo} width="100" style={{ padding: 20 }} />
                  <MenuA.Item key="/home">{t('menu.home')}</MenuA.Item>
                  <MenuA.Item key="/catalogo/p">{t('footer.menu.prod')}</MenuA.Item>
                  <MenuA.Item key="/login">{t('footer.menu.login')}</MenuA.Item>
                  <MenuA.Item key="/register">{t('home.portada.a')}</MenuA.Item>
                </MenuA>
              ) : (
                <MenuA
                onClick={hanlekey}
                  className={menuMovil === true ? "showMenu" : "menuMovil"}
                  style={{ width: 256 }}
                  defaultSelectedKeys={["1"]}
                  defaultOpenKeys={["sub1"]}
                  mode="inline"
                >
                  <img src={Logo} width="100" style={{ padding: 20 }} />
                  <MenuA.Item icon={<HomeOutlined />} key="/home">{t('menu.home')}</MenuA.Item>
                  <MenuA.Item icon={<BookOutlined />} key="/catalogo">{t('footer.menu.prod')}</MenuA.Item>
                  <MenuA.Item icon={<UserOutlined />} key="/customer">{t('menu.my_acc')}</MenuA.Item>
                  <MenuA.Item icon={<WechatOutlined />} key="/chat">Chat</MenuA.Item>
                  <MenuA.Item icon={<LogoutOutlined />} key="logout">{t('menu.logout')}</MenuA.Item>
                </MenuA>
              )}
            </div>
          </div>
        ) : !user ? (
          <div style={{display: 'flex', alignItems: 'center'}}>
            <Text className="link_text" onClick={() => {
              home.setModalView('login')
              home.toggleModal()
            }}>{t('footer.menu.login')}</Text>
            <span> | </span>
            <Text className="link_text" onClick={() => {
              home.setModalView('register')
              home.toggleModal()
            }}>{t('home.portada.a')}</Text>
          </div>
        ) : (
          <Space align="center">            
            <Button type="primary" style={{ borderRadius: 0 }}>
              <Link to="/customer">{t('menu.my_acc')}</Link>
            </Button>
            <Badge dot={dot}>
            <Button size="large" onClick={toChat} icon={<WechatOutlined />} type="link" />
            </Badge>
            <Button type="link" onClick={logout}>
            {t('menu.logout')}
            </Button>
          </Space>
        )}
      </div>
      </>
    );
}

export default withRouter(Menu);