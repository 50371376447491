import { Button, Form, Input, Modal, Typography } from 'antd'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next';
import { HomeContext } from '.';
import { LoginFacebook, LoginGoogle } from '../../components/LoginSocial';
import { AuthContext } from '../../hooks/useAuthContext';
import FormLogin from './components/formLogin';
import FormRecoverPass from './components/formRecoover';
import FormRegister from './components/FormRegister';


const {Title, Text} = Typography;

export default function ModalAuth() {
  const {t} = useTranslation();
    const {
        toggleModal,
        open,
        modal,
        setModalView
    } = useContext(AuthContext)

    const titleModal = {
      login: t('auth.login.title'),
      register: t('auth.login.create'),
      recover: t('auth.recooverpass.title'),
    }

    return(<Modal 
        width={420}
        className='modal_login'
        // closable={false}
        onCancel={toggleModal}
        footer={null}
        open={open}>
            <Title level={2} className='title_login'>
              {titleModal[modal]}
            </Title>
            {modal === 'login' && <FormLogin />}
            {modal === 'register' && <FormRegister />}
            {modal === 'recover' && <div>
              <FormRecoverPass />
              <Text type='secondary'>{t('auth.recooverpass.text')}</Text>
              </div>}
            {modal !== 'recover' && <div>
            <Text className='text_login'>{t('auth.login.or')}</Text>
            {/* <LoginFacebook />
              <LoginGoogle /> */}
            </div>}
              {modal === 'login' && <Text onClick={() => setModalView('recover')} className='text_login link_login pd_top' type='link'>{t('auth.login.lost')}</Text>}
              {modal === 'recover' && <Text onClick={() => setModalView('login')} className='text_login link_login pd_top' type='link'>{t('auth.recooverpass.cancel')}</Text>}
              {modal === 'login' && <Text className='text_login'>{t('auth.login.not')} <Text onClick={() => setModalView('register')} className='link_login'>{t('auth.login.create')}</Text></Text>}
              {modal === 'register' && <Text className='text_login'>{t('auth.create.not')} <Text onClick={() => setModalView('login')} className='link_login'>{t('auth.login.title')}</Text></Text>}
        </Modal>)
}