import axios from "axios"
import Cookies from 'js-cookie';
// const token = Cookies.get('token');
// const BASE_API = "http://localhost:4000/api/"; 
// export const BASE_IMG = "http://localhost:4000/";
export const BASE_IMG = "https://api.upgrap.com/";
const BASE_API = "https://api.upgrap.com/api/";


export const opt1 = {
    'Content-Type': 'application/json'
}

export const opt2 = () => {
    return {
        "Accept": 'application/json',
        "x-access-token": Cookies.get('token')
    }
}
export const opt3 = () => ({
    'Content-Type': 'multipart/form-data',
    // "x-access-token": Cookies.get('token')
})

export const getData = async (url, option) => {
    return await new Promise((resolve, reject) => {
        axios.get(BASE_API+url, {headers: option ? option : opt1}).then(res => {
            resolve(res.data);
        }).catch(err => reject(err.response));
    })
}

export const addData = async (url, data, option) => {
    return await new Promise((resolve, reject) => {
        axios.post(BASE_API+url, data, {headers: option}).then(res => {
            resolve(res.data);
        }).catch(err => reject(err.response));
    })
}

export const getDataById = async (url, id, option) => {
    return await new Promise((resolve, reject) => {
        axios.get(BASE_API+url+id, {headers: option}).then(res => {
            resolve(res.data);
            console.log(res)
        }).catch(err => {
            console.log(err)
            reject(err.response)
        });
    })
}

export const UploadFile = async (url, data, option) => {
    return await new Promise((resolve, reject) => {
        fetch(BASE_IMG+url, {
            method: 'POST',
            headers: {"Accept": "*/*"},
            body: data,
        }).then(res => res.json())
        .then(response => resolve(response))
        .catch(err => {
            console.log(err);
            reject(err.response);
        });
    })
}


export const updateData = async (url, data, id, option) => {
    return await new Promise((resolve, reject) => {
        axios.put(BASE_API+url+id, data, {headers: option}).then(res => {
            resolve(res.data);
        }).catch(err => reject(err.response));
    })
}

export const deleteData = async (url, id, option) => {
    return await new Promise((resolve, reject) => {
        axios.delete(BASE_API+url+id, {headers: option}).then(res => {
            resolve(res.data);
        }).catch(err => reject(err.response));
    })
}