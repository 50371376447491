import { message } from "antd";
import Cookies from "js-cookie";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { addData, opt1, opt2, updateData } from "../utils/api";
import { getStorage, setStorage, USER } from "../utils/storage";

export default function useProfile() {
    const {t} = useTranslation()
    const [user, setUser] = useState(getStorage(USER))
    const [loading, setLoading] = useState(false);
    const [modalPass, setModalPass] = useState(false)
    const [modalCp, setModalCp] = useState(false)

    // modal pass
    const toggleModalpass = () => {
        setModalPass(!modalPass);
    }

    //modal change pass
    const toggleModalChangepass = () => {
        setModalCp(!modalCp);
    }

    const onChangePass = async (values) => {
        try {
            setLoading(true);
            let response = await addData('signin', {password: values.password, email: user.email}, opt1);
            Cookies.set('token', response.token, {expires: 60});
            response = await addData('customer/changepass', {password: values.newPass, id: user.id}, opt2());
            if(response.code !== 'success') {
                throw response;
            } else {
                message.success(t('alerts.updatedpass'))
                setLoading(false);
                return true;
            }
        } catch (err) {
            if(err.data.code === "password/invalid") {
                message.error(t('alerts.errorup'));
            } else if(err.data.code === "email/empty") {
                message.error(t('alerts.emaillogin'));
            } else {
                message.error(t('alerts.errorsave'));
            }
            setLoading(false);
            return false;
        }
    }

    const onSaveData = async (values) => {
        try {
            setLoading(true);
            const response = await updateData('customer/', values, user.id, opt2());
            if(response.user) {
                setStorage(USER, response.user);
                setUser(response.user);
                message.success(t('alerts.updatedsuccess'));
                setLoading(false);
                return
            }
            throw response;
        } catch (error) {
            message.success(t('alerts.errorsave'));
            setLoading(false);
        }
    }

    return {
        user,
        modalPass,
        toggleModalpass,
        loading,
        onSaveData,
        modalCp,
        toggleModalChangepass,
        onChangePass
    }
}