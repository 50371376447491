import { message } from "antd";
import Cookies from "js-cookie";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { addData, getDataById, opt1, opt2 } from "../utils/api";
import { getStorage, USER } from "../utils/storage";

export default function useProduct() {
    const {t} = useTranslation();
    const history = useHistory();
    const [data, setData] = useState(null);
    const [user] = useState(getStorage(USER));
    const [token] = useState(Cookies.get('token'));
    const [loading, setLoading] = useState(false);

    const getProduct = async (id) => {
        try {
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
            console.log(id)
            const product = await getDataById('products/', id, opt1);
            console.log(product)
            setData(product);
        } catch (error) {
            message.error(t('alerts.errorget'))
        }
    } 

    const onToChat = async (data) => {
        if (!user && !token) return message.info(t('alerts.errortochat'));
        if (!user.validate_phone) return message.info(t('alerts.validatephone'));
        try {
            setLoading(true)
            const response = await addData("chatlist/create", {
                "sender": user.id,
                "receiver": data.id_user,
                "message": `${t('alerts.message')}: ${data.name}`,
                "fecha": moment().format(),
                "issee": false,
                "type": "message",
                "is_file": false
            }, opt2())
            if(!response.success) throw response;
            setLoading(false)
            history.push('/chat/'+data.id_user);
        } catch (error) {
            message.error(t('alerts.errorsendmsg'))
            setLoading(false)
        }

    }

    return {
        data,
        getProduct,
        onToChat,
        loading
    }

}