import { Form, Input, Modal } from 'antd';
import React, { useContext } from 'react'
import { ProfileContext } from '../EditData';

export default function ModalPass() {
    const {
        modalPass,
        toggleModalpass
    } = useContext(ProfileContext)
    return(
        <Modal onCancel={toggleModalpass} open={modalPass} title="Ingresa tu contraseña">
            <Form>
                <Form.Item>
                    <Input.Password />
                </Form.Item>
            </Form>
        </Modal>
    );
}