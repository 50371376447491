import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import { useHistory } from 'react-router-dom';
import { addData, opt1 } from '../utils/api';
import Cookies from 'js-cookie'
import { message } from 'antd';
import {GoogleOutlined} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { gapi } from 'gapi-script';
import { useEffect } from 'react';

export const LoginFacebook = () => {
  const {t} = useTranslation();
    let history = useHistory()

    const responseFacebook = async (response) => {
        await addData('signinsocial', {name:response.name, email: response.email, userid: response.id, singin_method: 'facebook'}, opt1).then(res => {
          // console.log(res);
          Cookies.set('token', res.token, {expires: 1});
          window.localStorage.setItem('user', JSON.stringify(res.user));
          message.success(t('alerts.loginsocialsuccess'));
          setTimeout(() => {
            // this.setState({loading: false});
            history.push('/customer');
          }, 1000);
        }).catch(err => {
          console.log(err)
          if(err.data.code === 'email/registered') {
            message.error(t('alerts.emailregister'), 9);
          } else {
            message.error(t('alerts.errorregister'))
          }
        });
        
      }

    return (
        <FacebookLogin

        appId="891037061645114"
        autoLoad={false}
        cssClass="my-facebook-button-class"
        icon="fa-facebook"
        textButton={<span>{t('auth.login.fb')}</span>}
        fields="name,email,picture"
        callback={responseFacebook} />
    )
}

export const LoginGoogle = () => {
  const {t} = useTranslation();
  let history = useHistory()

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: "800703504715-a0tsoaaqghg5k7ldd4rgqabmsnu8gbbi.apps.googleusercontent.com",
        scope: ''
      });
    };
    gapi.load('client:auth2', initClient);
  }, []);
    
  const responseGoogle = async (response) => {
    console.log(response)
    const {profileObj} = response
    console.log(response)
    await addData('signinsocial', {name:profileObj.name, email: profileObj.email, userid: profileObj.googleId, singin_method: 'google'}, opt1).then(res => {
      console.log(res);
      Cookies.set('token', res.token, {expires: 1});
      window.localStorage.setItem('user', JSON.stringify(res.user));
      message.success(t('alerts.loginsocialsuccess'));
      setTimeout(() => {
        // this.setState({loading: false});
        history.push('/customer');
      }, 1000);
    }).catch(err => {
      console.log(err)
      if(err.data.code === 'email/registered') {
        message.error(t('alerts.emailregister'), 9);
      } else {
        message.error(t('alerts.errorregister'))
      }
      
    });
    // console.log({name:profileObj.name, email: profileObj.email, userid: profileObj.googleId, singin_method: 'google'});
  }

  const failedGoogle = (response) => {
    console.log(response)
  }

    return (
        <GoogleLogin
        // clientId="404466753320-8ob9c44f8na5ajcr317p25qv92juvv6e.apps.googleusercontent.com"
        clientId='800703504715-a0tsoaaqghg5k7ldd4rgqabmsnu8gbbi.apps.googleusercontent.com'
        buttonText="Login"
        onSuccess={responseGoogle}
        onRequest={failedGoogle}
        scope="profile email"
        render={renderProps => (
          <button className="my-google-button-class" onClick={renderProps.onClick} disabled={renderProps.disabled}>
            <GoogleOutlined />
            <span>{t('auth.login.google')}</span>
          </button>
        )}
        // onFailure={this.responseGoogle}
        cookiePolicy={'single_host_origin'}
        isSignedIn={true}
      />
    )
}