import { Button, Input, Modal, Typography } from 'antd';
import React from 'react'
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import usePublish from '../../hooks/usePublish';
import ProductCustomer from './components/ProductCustomer';

export default function Publish() {
    const {t} = useTranslation();
    const {
        getMyProducts, 
        products,
        time,
        createPublish,
        handleMenuClick,
        open,
        user,
        code,
        closeModal,
        reSendOTP,
        sendCode,
        onChangeCode
    } = usePublish();
    

    useEffect(() => {
        getMyProducts();
    }, []);

    return(<div className="publish_main">
    <Typography.Title level={3}>{t('publish.title')}</Typography.Title>
    <Button type="primary" onClick={createPublish}>{t('publish.btn')}</Button>
    {products.length > 0 ? 
    products.map(p =>
        <ProductCustomer 
        key={p.id}
        onDelete={() => handleMenuClick(p.id)} 
        data={p} />
    ) : <div>{t('publish.not_post')}</div>}

    <Modal title={t('publish.modal.title')} 
    // cancelText={t('publish.modal.cancel')}
    // okText={t('publish.modal.ok')}
    // onOk={this.sendCode}
    footer={false}
    onCancel={closeModal} 
    visible={open}>
        <div className="inputsCode">
            <p>{t('publish.modal.text')} <strong>{user && user.prefix+user.phone}</strong></p>
            <Input
            maxLength={6} 
            minLength={6} 
            // onKeyDown={this.onChangeCode}
            onChange={onChangeCode}
            value={code} 
            className="input_new" />
            <p><small>{t('publish.modal.text1')} {time>0?time:0} {t('publish.modal.seconds')} <span style={{fontWeight: 700, color: '#32BAB0'}}>{t('publish.modal.text2')}</span></small></p>
        </div>
        <div style={{textAlign: 'right'}}>
            <Button style={{marginRight: 20}} disabled={time > 0} onClick={reSendOTP}>{t('publish.modal.cancel')}</Button>
            <Button type="primary" onClick={sendCode}>{t('publish.modal.ok')}</Button>
        </div>
    </Modal>
</div>);
}