import { message } from "antd";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { addData, getData, opt1 } from "../utils/api";
import { setStorage, USER } from "../utils/storage";
import Cookies from 'js-cookie'
import { useTranslation } from "react-i18next";

export default function useHome() {
    const {t} = useTranslation()
    const history = useHistory();
    const [categories, setCategories] = useState([])
    const [products, setProduct] = useState([])
    const [open, setOpen] = useState(false)
    const [modal, setModal] = useState("login")
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('');
    const [term, setTerm] = useState(false);

    const getCategories = async () => getData('categories')
    .then(response => setCategories(response))
    .catch((err) => Promise.reject(err))

    const getProducts = async () => getData('products?limit=8&count=false')
    .then(response => setProduct(response.rows))
    .catch((err) => Promise.reject(err))



    //* buscar productos en la barra de busqueda
    const onKey = async (e) => {
        if(e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();
            // const busqueda = await getData('products/search/'+this.state.search, opt1);
            // console.log(busqueda);
            history.push(`/catalogo/s/${search}/1`);
        }
    }

    //* buscar productos time real
    const onSearch = (e) => {
        setSearch(e.target.value);
    }

    //* toggle modal login
    const toggleModal = () => {
        setOpen(!open)
    }

    //* toggle modal login
    const toggleModalTerm = () => {
        setTerm(!term)
    }

    //* view modal
    const setModalView = (str) => {
        setModal(str)
    }

    //* ################
    //* login

    const onLogin = async (values) => {
        try {
            setLoading(true);
            const response = await addData('signin', values, opt1);
            Cookies.set('token', response.token, {expires: 60});
            setStorage(USER, response.user);
            setTimeout(() => {
                setLoading(false);
                console.log(history)
                history.push('/customer');
          }, 1000);
        } catch (err) {
            if(err.data.code === "password/invalid") {
                message.error(t('alerts.errorup'));
            } else if(err.data.code === "email/empty") {
                message.error(t('alerts.emaillogin'));
            } else {
                message.error(t('alerts.errorlogin'));
            }
            setLoading(false);
        }
    }

    //* ################
    //* register

    const onRegister = async (values) => {
        try {
            setLoading(true);
            const response = await addData('signup', {...values, singin_method: 'email'}, opt1)
            Cookies.set('token', response.token, {expires: 60});
            setStorage(USER, response.user);
            message.success(t('alerts.loginsocialsuccess'));
            setTimeout(() => {
              setLoading(false)
              history.push('/customer');
            }, 1000);
            
        } catch (err) {
        if(err.data.code === 'email/registered') {
            message.error(t('alerts.emailregister'), 9);
        } else {
            message.error(t('alerts.errorregister'));
        }
        setLoading(false)
    }
}

  const onRecoverPass = async (values) => {
    try {
        setLoading(true);
        const response = await addData('recover', {email: values.email}, opt1);
        if(response.code !== 'success') {
            throw response;
        }
        message.success(t('alerts.sendemail'));
        setLoading(false);
    } catch (error) {
        setLoading(false);
        message.error(t('alerts.ersendmail'));
    }
  };

    return {
        categories,
        products,
        onKey,
        onSearch,
        getCategories,
        getProducts,
        open,
        toggleModal,
        modal,
        setModalView,
        onLogin,
        loading,
        onRegister,
        term,
        toggleModalTerm,
        onRecoverPass
    }
}