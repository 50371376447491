import { Button, Card, Col, Image, Row } from 'antd';
import moment from 'moment';
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import Footer from '../../components/Footer';
import MenuP from '../../components/Menu';
import useHome from '../../hooks/useHome';
import useProduct from '../../hooks/useProduct';
import { BASE_IMG } from '../../utils/api';
import Products from '../Home/Products';
import notImage from '../../images/no-image.jpg';
import TimeAgo from 'javascript-time-ago'
import es from 'javascript-time-ago/locale/es'
import { useTranslation } from 'react-i18next';
TimeAgo.addDefaultLocale(es)
const timeAgo = new TimeAgo('es-ES')

export default function Product() {
  const {t} = useTranslation();
    const params = useParams();
    const {getProducts, products} = useHome();
    const {getProduct, data, onToChat, loading} = useProduct();
    useEffect(() => {
        getProduct(params.id)
        getProducts()
    }, [params])

    return(<div className="product_detail_main">
    <MenuP onKey={() => {}} onSearch={() => {}} search={true} catalogo={false} />
    {data !== null && (
      <Row gutter={[16, 0]} className="row_product_page" justify="space-between">
        <Col lg={8}>
            <Image width={"100%"} 
            height={'100%'} 
            fallback={notImage}
            src={BASE_IMG+'public/'+data.photo} 
            alt={data.name} />
        </Col>
        <Col lg={12}>
          <Card>
          {/* <Helmet>
      <title>upgrap | {product.name}</title>
    </Helmet> */}
            <div>
              <p>{`${data.estado === 'nuevo' ? t('product.new') : t('product.used')} | ${data.pais}, ${data.city}`}</p>
              <h1>{data.name}</h1>
              <p>{data.description}</p>
              <p><strong style={{color: '#19726c'}}>{t('product.change')}</strong> {data.change}</p>
              <p>
                <strong style={{color: '#19726c'}}>{t('product.category')}:</strong> {data.categoria} | {timeAgo.format(moment(data.fecha).unix() * 1000)}
              </p>
            </div>
            <Button
                  style={{ width: "100%" }}
                  type="primary"
                  loading={loading}
                  onClick={() => onToChat(data)}
                >
                  {t('product.btni')}
                </Button>
          </Card>
                  <Image.PreviewGroup>
                  <Row align="middle">
          
              {data.images &&
              data.images.map(im =>
              <Col lg={8}>
                  <Image width={"100%"} src={im} />
                  </Col>)}
                                         
            </Row>
                </Image.PreviewGroup>
        </Col>
      </Row>
    )}

    <div style={{ padding: "5%" }}>
      <Products data={products} />
    </div>

    <Footer />
  </div>);
}